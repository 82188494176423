import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import moment from 'moment';
const config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    padding: '20px',
    borderRadius: '10px',
    overflowX: 'auto',
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '20px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  responsiveTable: {
    maxWidth: '800px',
    margin: '0 auto',
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'center',
    fontSize: '1rem',
    '& th, & td': {
      padding: '7px',
      border: `1px solid ${theme.palette.primary.main}`,
      whiteSpace: 'nowrap',
    },
    '& th': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    '& td': {
      backgroundColor: theme.palette.background.paper,
    },
    '& tr:hover': {
      backgroundColor: 'rgba(25, 25, 25, 0.8)',
    },
    '& button': {
      padding: '5px 10px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& thead': {
        display: 'none',
      },
      '& tr': {
        marginBottom: '0.625rem',
        display: 'block',
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '& td': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'right',
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.background.default}`,
        '&::before': {
          content: 'attr(data-label)',
          float: 'left',
          fontWeight: 'bold',
        },
      },
    },
  },
}));


const PurchaseHistory = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    loading: true,
    error: null,
    purchases: [],
  });

  useEffect(() => {
    fetch("/api/user/purchasehistory", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.json())
    .then(data => {
      if (data.resultCode !== config.resultCodeTable.SUCCESS) {
        setState({ loading: false, error: true, purchases: [] });
      } else {
        setState({ loading: false, error: false, purchases: data.results.rows.reverse() });
      }
    })
    .catch(() => {
      setState({ loading: false, error: true, purchases: [] });
    });
  }, []);

  const renderContent = () => {
    if (state.loading) 
    {
      return (
        <div className={classes.loadingContainer}>
          <ReactLoading type="bubbles" height={"128px"} width={"128px"} />
        </div>
      );
    }

    if (state.error) 
    {
      return <p>An error has occurred. Please try again later.</p>;
    }

    if (state.purchases.length === 0) 
    {
      return <p>No transactions found.</p>;
    }

    return (
      <table className={classes.responsiveTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Origin Cash</th>
            <th>Invoice ID</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {state.purchases.map((purchase, index) => {
            const purchaseDate = moment(purchase[1].value).format('DD-MM-YYYY HH:mm');
            const OCAmount = purchase[5].value;
            const currencyShort = purchase[6].value;
            const currencyAmount = purchase[2].value;
            const EURAmount = purchase[7].value;
            const transactionID = purchase[3].value;

            let amountText = `${EURAmount.toLocaleString()}€`;
            if (currencyShort !== "EUR") 
            {
              amountText = `${currencyAmount.toLocaleString()}${currencyShort} (${EURAmount.toLocaleString()}€)`;
            }
            
            return (
              <tr key={index}>
                <td data-label="Date">{purchaseDate}</td>
                <td data-label="Origin Cash">{OCAmount}</td>
                <td data-label="Transaction ID">{transactionID}</td>
                <td data-label="Amount">{amountText}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Purchase History</h1>
      {renderContent()}
    </div>
  );
};

export default PurchaseHistory;
