import React, { useState } from 'react'
import { Button } from '../Button';
import '../../style/Admin.css';

var config = require('../Config/Config');

export default function BanAccount(props) {
    const [banAccountFailed, setBanAccountFailed] = useState(false);
    const [banAccountSuccess, setBanAccountSuccess] = useState({});
  
    function PostBanAccount()
    {
        var accountIDList = document.getElementById('accountIDList').value;
        var banTopic = document.getElementById('banTopic').value;
        var banDescription = document.getElementById('banDescription').value;
        var expirationDate = document.getElementById('expirationDate').value;
  
        fetch("/api/admin/ban", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({accountIDList: accountIDList, banTopic: banTopic, banDescription: banDescription, expirationDate: expirationDate})
        })
        .then(res => res.json())
        .then(data => 
            {
                if (data.resultCode !== config.resultCodeTable.SUCCESS)
                {
                    setBanAccountFailed(true);
                }
                else
                {
                    setBanAccountFailed(false);
                    setBanAccountSuccess(data);
                }
            }
        )
    };
  
    return (
        <>
        {
            config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
                <div className = "ban__account">
                    {
                        config.isEmptyObject(banAccountSuccess) ? (
                            <>
                                <h3>Account ID List</h3>
                                <input type = "text" id = "accountIDList" placeholder = "Enter account ID List, Format : 1000,1001,..."/>
                                <h3>Ban Topic</h3>
                                <input type = "text" id = "banTopic" placeholder = "Enter the category for the offense"/>
                                <h3>Ban Description</h3>
                                <input type = "text" id = "banDescription" placeholder = "Full description of ban details"/>
                                <h3>Expiration Date</h3>
                                <input type = "datetime-local" id = "expirationDate"/>
                                <br/>
                                <Button onClick = {PostBanAccount}>Send</Button>
                                { banAccountFailed ? (<><br/><p>Account does not exist.</p></>) : (<br/>)}
                            </>
                        ) : (
                            <>
                                <p>{banAccountSuccess.results.message}</p>
                            </>
                        )
                    }
                
                </div>
            )
        }
      </>
    );
  }
