import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { GrInstallOption } from 'react-icons/gr';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#F7AB00',
    color: '#000',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.2s ease',
      backgroundColor: '#F7AB00',
      color: '#000'
    }
  },
}));

export default function InstallerButton() {
  const classes = useStyles();

  return (
    <div>
      <Button 
        variant = "contained"
        className = {classes.button}
        startIcon = {<GrInstallOption color = "#000"/>}
      >
        Installer
      </Button>
    </div>
  );
}