import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Typography, Button, CircularProgress, Chip } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { URLs } from '../Data';
import { Link } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import UpdateIcon from '@material-ui/icons/Update';

const useStyles = makeStyles((theme) =>
  createStyles({
    newsSection: {
        padding: '1rem 5%',
        textAlign: 'center',
        height: '100vh',
        overflowY: 'hidden',
        fontFamily: 'Roboto, sans-serif',
        position: 'relative',
        userSelect: 'none',
    },
    newsCard: {
        maxWidth: '238px',
        margin: '5px',
        backgroundColor: theme.palette.background.paper,
        transition: 'transform 0.3s',
        '&:hover': {
        transform: 'scale(1.03)',
        },
    },
    media: {
        height: 150,
    },
    newsButton: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    },
    cardContent: {
        height: '195px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    moreNewsText: {
        color: '#808080',
        fontSize: '0.75rem',
        marginTop: '1rem',
        textAlign: 'center',
    },
    linkStyle: {
        color: '#b0b0b0',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    slickList: {
        width: '80%',
        margin: '0 auto',
    },
    onlinePlayers: {
        left: '0%',
        color: '#009688',
    },
    uniquePlayers: {
        right: '0%',
        color: '#009688',
    },
    chip: {
        position: 'absolute',
        margin: theme.spacing(0.5),
        backgroundColor: '#343030',
        color: 'white',
        fontWeight: 'bold',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        borderRadius: '25px',
        '&:hover': {
          backgroundColor: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
          boxShadow: '0 5px 7px 2px rgba(255, 105, 135, .5)'
        },
        transition: 'background-color 0.3s, box-shadow 0.3s',
        '& .MuiChip-icon': {
            color: '#FFFFFF',
        },
    },
  })
);

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const json = await response.json();
        setData(json);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
};

function Launcher() {
  const classes = useStyles();
  const newsApi = 'https://dnorigins.com/news/ghost/api/v4/content/posts/?key=5dbd62f5253ca5fe67ae700a8f&limit=5';
  const playerCountApi = "/api/serverStatus/playerCount";

  const { data: newsData, loading: newsLoading } = useFetch(newsApi);
  const [onlinePlayers, setOnlinePlayers] = useState("...");
  const [uniquePlayers, setUniquePlayers] = useState("...");

useEffect(() => {
    const fetchPlayerCount = () => {
        fetch(playerCountApi, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
        setOnlinePlayers(data.results.online);
        setUniquePlayers(data.results.weekly);
        })
        .catch(error => console.error("Error fetching player count:", error));
    };

    fetchPlayerCount();

    const intervalId = setInterval(fetchPlayerCount, 10000);

    return () => clearInterval(intervalId);
    }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: false,
    initialSlide: 0,
  };

  return (
    <div className={classes.newsSection}>
      <Chip
        icon={<PeopleIcon />}
        label={`Online: ${onlinePlayers}`}
        className={classes.chip + " " + classes.onlinePlayers}
      />
      <Chip
        icon={<UpdateIcon />}
        label={`Weekly: ${uniquePlayers}`}
        className={classes.chip + " " + classes.uniquePlayers}
      />
      <Slider {...settings} className={classes.slickList}>
        {newsData?.posts.map((news, index) => (
          <Card className={classes.newsCard} key={index}>
            <CardMedia
              className={classes.media}
              image={news.feature_image}
              title={news.title}
            />
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" component="h2">
                {news.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {news.excerpt}
              </Typography>
              <Button 
                className={classes.newsButton}
                variant="contained" 
                color="secondary" 
                href={news.url} 
                target="_blank">
                Read More
              </Button>
            </CardContent>
          </Card>
        ))}
      </Slider>
      <Typography className={classes.moreNewsText}>
        For more updates, check out our #announcements channel on{" "}
        <Link to={URLs.discord} target="_blank" rel="noopener noreferrer" className={classes.linkStyle}>
          DNOrigins Discord
        </Link>.
      </Typography>
    </div>
  );
}

export default Launcher;
