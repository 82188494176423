import React, { useState } from 'react'
import { Button } from '../Button';
import {jobCodes} from '../Data';
import '../../style/Admin.css';
import ReactLoading from 'react-loading';

var config = require('../Config/Config');

export default function CharacterIdentification(props) {
    const [charIDFailed, setCharIDFailed] = useState(false);
    const [charData, setCharData] = useState({});
    const [loading, setLoading] = useState(false);
    function FetchCharacter()
    {
        setLoading(true);
        //accountName
        var characterName = document.getElementById('characterName').value;
  
        fetch("/api/admin/charid?characterName=" + characterName, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => 
            {
                if (data.resultCode !== config.resultCodeTable.SUCCESS)
                {
                    setCharIDFailed(true);
                }
                else
                {
                    setCharIDFailed(false);
                    setCharData(data);
                }
                setLoading(false);
            }
        )
    };
    function formatDate(date)
    {
        return date.replace("T", " ").replace("Z", " ");
    } 
    return (
        <>
        {
            config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
                <div className = "char__identif">
                    {
                        config.isEmptyObject(charData) ? (
                            <>
                                <h1>Character Name</h1>
                                <input type = "text" id = "characterName" placeholder = "Enter a valid character name. Capitalization matters!"/>
                                <Button onClick = {FetchCharacter}>Search</Button>
                                { charIDFailed ? (<><br/><p>Character does not exist.</p></>) : (<br/>)}
                                {loading ? <ReactLoading type = {"bubbles"} height = {"128px"} width = {"128px"}/> : null}
                            </>
                        ) : (
                            <>
                            <h3>RESULTS</h3>
                            <table>
                                <thead>
                                    <th>
                                        Character ID
                                    </th>
                                    <th>
                                        Level
                                    </th>
                                    <th>
                                        Class
                                    </th>
                                    <th>
                                        Inventory Gold
                                    </th>
                                    <th>
                                        Storage Gold
                                    </th>
                                    <th>
                                        AccoutLevelCode
                                    </th>
                                    <th>
                                        Account Name
                                    </th>
                                    <th>
                                        AccountID
                                    </th>
                                    <th>
                                        Create Date
                                    </th>                                 
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {charData.results.rows[0][0].value}
                                        </td>
                                        <td>
                                            {charData.results.rows[0][1].value}
                                        </td>
                                        <td>
                                            {jobCodes[charData.results.rows[0][2].value]}
                                        </td>
                                        <td>
                                            {parseInt(charData.results.rows[0][3].value / 10000)}
                                        </td>
                                        <td>
                                            {parseInt(charData.results.rows[0][4].value / 10000)}
                                        </td>
                                        <td>
                                            {charData.results.rows[0][5].value}
                                        </td>
                                        <td>
                                            {charData.results.rows[0][6].value}
                                        </td>
                                        <td>
                                            {charData.results.rows[0][7].value}
                                        </td>
                                        <td>
                                            {formatDate(charData.results.rows[0][8].value)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>)
                    }
                
                </div>
            )
        }
      </>
    );
  }
