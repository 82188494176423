import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import '../../style/Admin.css';
import Header from '../Header';
var config = require('../Config/Config');

function Admin(props) {
    return (
        <>
            {
                config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
                    <div className = "admin__container">
                        <Header/>
                        <div className = "admin__panel">
                            <Link to='/admin/accid' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Account Identification</Button>
                            </Link>
                            <Link to='/admin/charid' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Character Identification</Button>
                            </Link>
                            <Link to='/admin/sendcash' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Send Origin Cash</Button>
                            </Link>
                            <Link to='/admin/ban' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Ban Account</Button>
                            </Link>
                            <Link to='/admin/rewards' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Event Rewards</Button>
                            </Link>
                            <Link to='/admin/sendrewards' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Event Reward Distribution</Button>
                            </Link>
                            <Link to='/admin/sendmails' className = "btn-link">
                                <Button buttonStyle = "btn--outline">Send Mails</Button>
                            </Link>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Admin
