import React from 'react';
import DirectDownloadButton from './DirectDownloadButton';
import InstallerButton from './InstallerButton';
import installer from '../../images/dnoinstaller.png';
import Header from '../Header';
import { URLs } from '../Data';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    downloadPage: {
        backgroundColor: '#121212',
        fontFamily: 'Roboto, sans-serif',
        padding: '2% 0',
        textAlign: 'center',
        maxWidth: '70%',
        margin: 'auto',
        border: '2px solid #ffbb00',
        borderRadius: '8px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '95%',
        }
    },
    downloadContent: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    btnLink: {
        margin: '1rem',
        display: 'inline-block',
        width: '100%',
        textAlign: 'center',
    },
    downloadAlternative: {
        marginBottom: '1rem',
    },
    systemRequirements: {
        marginTop: '3rem',
        background: '#1b1b1b',
        padding: '1rem',
        borderRadius: '5px',
        width: '100%',
        '& .MuiListItem-root': {
            justifyContent: 'center'
        },
        '& .MuiListItemText-root': {
            textAlign: 'center'
        }
    },    
    installerPreview: {
        marginTop: '1rem',
    },
    installerImg: {
        maxWidth: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            margin: '0 auto',
        }
    },
    linkStyle: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

function Download() {
    const classes = useStyles();

    return (
        <div className={classes.downloadPage}>
            <Header />
            <div className={classes.downloadContent}>
                <h2>Download the Game</h2>
                <a href={URLs.installer1} target="_blank" rel="noreferrer" className={classes.btnLink}>
                    <InstallerButton buttonStyle="btn--outline" />
                </a>
                <div className={classes.downloadAlternative}>
                    <p>Having installer issues? Get help on{" "}
                    <Link to={URLs.discord} target="_blank" rel="noopener noreferrer" className={classes.linkStyle}>
                        DNOrigins Discord
                    </Link> 
                    {" "}or try the direct download:</p>
                    <a href={URLs.directdl} target="_blank" rel="noreferrer" className={classes.btnLink}>
                        <DirectDownloadButton buttonStyle="btn--outline" />
                    </a>
                </div>
                <div className={classes.installerPreview}>
                    <p>Here's a quick look at the installer. Special thanks to Vahr for its creation!</p>
                    <img src={installer} alt="dno_installer" className={classes.installerImg}/>
                </div>
                <div className={classes.systemRequirements}>
                    <h3>Recommended System Requirements:</h3>
                    <List className={classes.systemRequirements}>
                        <ListItem>
                            <ListItemText primary="Windows 10 or above (Other systems may work but are not supported)" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Intel(R) Core i5 4670 or higher" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="8GB RAM or higher" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="NVIDIA GeForce GTX 750" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="15GB of storage space" />
                        </ListItem>
                    </List>
                </div>
            </div>
        </div>
    )
}

export default Download;
